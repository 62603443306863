<template>
	<div class="flex-1 bg-white pa-24 radius-20 mb-30">
		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
		</div>
		<!-- //고정해더 -->
		<div
			v-if="!item.is_check"
			class="mt-30"
		>
			<div class="">
				<div class="">
					<div class="">
						<div class="under-line mb-20 pb-20">
							<div class="justify-space-between items-center">
								<h5 class="color-333">{{ $language.subscribe.title_subscribe_use }} <!-- 플랜 판매 --></h5>

								<p class="toggle-group">
									<!-- TODO : 수신 허용시 .on 클래스 추가 -->
									<span
										class="btn-toggle"
										:class="{ on: item.subscrp_plan_state_code == 'SS00200002' }"
										@click="setPlaneState"
									><em class="hide">{{  $language.common.dark_mode }}</em></span>
								</p>
							</div>
							<!-- <div>플랜 판매여부를 설정합니다.</div> -->
						</div>

						<div class="mt-30">
							<h5 class="color-333">구독 플랜 아이콘</h5>
							<div class="mt-10 text-center">
								<label class="inline-block img-box-100 radius-12 overflow-hidden">
									<input-file-new type="file" class="input-box" accept="image/*" @change="setFile($event)" ref="file" file_upload_code_info="CM00700021" />
									<img :src="item.upload_img_file_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
								</label>
							</div>
						</div>
						<div
							class="mt-30"
						>
							<h5 class="flex-row items-center color-333">구독 플랜 이름</h5>
							<div class="mt-10">
								<div class="input-box radius-20 prl-20 justify-space-between">
									<input v-model="item.subscrp_plan_name" class="flex-1 mr-10" placeholder="구독 플랜명을 입력하세요" maxlength="20"/>
									<div class="color-bbb size-px-14"> {{ item.subscrp_plan_name.length }}/20</div>
								</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<div class="justify-space-between items-center">
								<h5 class="flex-row items-center color-333">구독 플랜 설명</h5>

								<div class="mt-10 text-right color-bbb size-px-14"> {{ item.subscrp_plan_desctn.length }}/300</div>
							</div>
							<div class="mt-10">
								<div class="input-box  radius-20 justify-space-between">
									<textarea
										v-model="item.subscrp_plan_desctn"
										class="flex-1 mr-10 pa-10 size-px-14"
										placeholder="구독 플랜명을 입력하세요"
										rows="10"
										maxlength="300"
									/>
								</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<div class="justify-space-between items-bottom">
								<h5 class="flex-row items-center color-333">플랜 가격 설정</h5>

								<div class="mt-10 text-right color-bbb size-px-12">3,000원 ~ 10,000,000원 설정</div>
							</div>
							<div class="mt-10">
								<div class="input-box prl-20 radius-20 justify-space-between">
									<input v-model="item.subscrp_amount" class="flex-1 mr-10" placeholder="플랜 가격을 입력하세요" maxlength="20" type="number" :rules="[$rules.max(item, 'subscrp_amount', 8)]"/>
									<div class="size-px-14">원</div>
								</div>
							</div>
						</div>

						<div
							class="mt-30"
						>
							<h5 class="flex-row items-center color-333">플랜 수수료({{ subscrp_plan_commis_rate }}%)</h5>
							<div class="mt-10">
								<div class="box-input pa-10-20 radius-20 justify-space-between bg-gray02" style="border: 1px solid #dadada;">
									<input v-model="plane_fee" class="flex-1 mr-10" placeholder="플랜 수수료" maxlength="20"/>
									<div class="size-px-14">원</div>
								</div>
							</div>
						</div>


						<div
							class="mt-30"
						>
							<h5 class="flex-row items-center color-333">플랜 정산 예정 금액</h5>
							<div class="mt-10">
								<div class="box-input pa-10-20 radius-20 justify-space-between bg-gray02" style="border: 1px solid #dadada;">
									<input v-model="plane_total" class="flex-1 mr-10" placeholder="구독 플랜명을 입력하세요" maxlength="20"/>
									<div class="size-px-14">원</div>
								</div>
							</div>
						</div>


						<div
							class="mt-30"
						>
							<div class="justify-space-between items-center">

								<h5 class="flex-row items-center color-333">결제 수단</h5>
								<div class="color-bbb size-px-12">구독 플랜 구입 시 결제 수단을 선택하세요.</div>
							</div>
							<div class="mt-15">

								<ul class="flex-row">
									<li
										v-for="(payment, p_index) in payment_list"
										:key="'payment_' + p_index"
										class="flex-row gap-5 align-center mr-10 cursor-pointer"
										@click="setPayment(payment)"
									>
										<v-icon v-if="payment.is_check" class="color-blue-29">mdi-checkbox-marked-circle</v-icon>
										<v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>

										<span class="size-px-14 line-height-1">{{ payment.payment_mthd_name }}</span>
									</li>
								</ul>
							</div>
						</div>


						<div
							class="mt-20 top-line pt-20"
						>
							<label
								@click="setAgree"
							>
								<h5 class="flex-row items-center items-center size-px-14">
									<v-icon v-if="item.is_agree" class="color-blue-29 mr-10 ">mdi-checkbox-marked-circle</v-icon>
									<v-icon v-else class="mr-10">mdi-checkbox-blank-circle-outline</v-icon>
									(필수)판매 이용약관
								</h5>
							</label>
							<div class="mt-15">
								<div class="color-888 size-px-14">
									정산 출금 신청 시 부가세 및 출금수수료가 추가 부가됩니다. 플랜 수수료의 경우 정책에 따라 변경될 수 있으며 약관에 의거 변경일로부터 100일전에 공지해드립니다.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="mt-30"
		>
			<div class="">
				<div class="">
					<div class="">
						<div class="justify-space-between">
							<div class="img-box-100 mr-10 radius-12 overflow-hidden"><img :src="item.upload_img_file_path" @error="$bus.$emit('onErrorImage', $event)" class="width-100"/></div>
							<div class="flex-1 gap-10 flex-column justify-space-between gap-10 size-px-14 text-left">
								<div class=" "><span class="label-plan label-sale">{{ item.subscrp_plan_state_name }}</span></div>
								<div class="size-px-16 font-weight-700 line-height-140">{{ item.subscrp_plan_name }}</div>
								<div class="size-px-16 font-weight-600 color-primary flex-row align-center gap-5">
									<div class="flex-row align-center gap-5">
										<img :src="require('@/assets/image/icon_won_blue.svg')" /> 
										<span class="line-height-1">
											{{ item.subscrp_amount | makeComma }}원
										</span>
									</div>
									<span class="size-px-13 font-weight-400 color-mafia-blue">/ 월 VAT 별도</span></div>
							</div>
						</div>
						<div
							class="mt-20 pa-20 radius-12"
							style="background-color: rgba(23, 28, 97, 0.03);"
						>
							<h4 class="flex-row items-center gap-8">
								<img :src="require('@/assets/image/web/setting_cartel/icon_subscribe_desc.svg')" /> 구독 플랜 설명</h4>
							<div
								:inner-html.prop="item.subscrp_plan_desctn | nl2br"
								class="size-px-14 mt-15 top-line pt-15 line-height-140"
							></div>
						</div>
						<div
							class="mt-20 pa-20 radius-12"
							style="background-color: rgba(23, 28, 97, 0.03);"
						>
							<h4 class="flex-row items-center gap-8">
								<img :src="require('@/assets/image/web/setting_cartel/icon_subscribe_payment.svg')" />
								결제수단</h4>
							<ul>
								<li
									v-for="(payment, p_index) in payment_mthd_list"
									:key="'payment_' + p_index"
									class="size-px-14 mt-15 top-line pt-15 line-height-140"
								>
									<div>{{ payment.payment_mthd_name }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="mt-auto pa-20 text-right "
		>
			<button
				v-if="item.is_check"
				@click="item.is_check = !item.is_check"
				class="btn-inline btn-primary-outline radius-20 mr-10"
				:disabled="is_save"
			>플랜 수정</button>
			<button
				v-if="item.is_check"
				@click="onPin"
				class="btn-inline btn-primary radius-20"
				:disabled="is_save"
			>플랜 저장</button>
			<button
				v-else
				@click="onPlaneSetting"
				class="btn-inline btn-primary radius-20"
				:disabled="is_save"
			>플랜 확인하기</button>
		</div>
	</div>
</template>

<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'SubscribePlaneDetail'
	, components: {InputFileNew}
	, props: ['user']
	, data: function(){
		return {

			program: {
				name: '플랜 및 판매 설정'
				, title: '플랜 및 판매 설정'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, from: 'mafia049'
				, bg_contents: 'bg-gray01'
				, cartel: ''
				, is_side: true

			}
			, item_cartel: {

			}
			, item:{
				subscrp_plan_number: 1
				, subscrp_plan_name: '루비'
				, subscrp_amount: 5000
				, subscrp_plan_desctn: '정보 우선 공개\n 미공개 사진 및 영상 공개\n 한달에 한번 오프라인 미팅'
				, subscrp_plan_state_code: ''
				, subscrp_plan_state_name: '판매중'
				, img_resolu_code: ''
				, img_file_path: ''
				, subscrp_months_count: '5000'
				, payment_mthd_list: []
				, upload_img_file_path: ''
				, is_agree: false
			}
			, subscrp_plan_commis_rate: 14
			, items_payment: []
		}
	}
	, computed: {
		plane_fee: function(){
			let t = 0;
			if(this.item.subscrp_amount > 0){
				t = this.item.subscrp_amount * this.subscrp_plan_commis_rate / 100
			}
			return t
		}
		, plane_total: function(){
			let t = 0;
			if(this.item.subscrp_amount > 0){
				t = this.item.subscrp_amount - (this.item.subscrp_amount * this.subscrp_plan_commis_rate / 100)
			}
			return t;
		}
		, payment_list: function(){
			return this.items_payment.filter( (item) => {

				if(item.payment_type_code == 'PM00300001'){
					item.payment_mthd_name = '신용카드'
				}else if(item.payment_type_code == 'PM00300003') {
					item.payment_mthd_name = '불렛'
				}else{
					for(let [key, coin] of Object.entries(this.$codes.tokens)) {
						item.key = key
						if(coin.coin_token_code == item.coin_token_code){
							item.payment_mthd_name = coin.coin_token_name
							break
						}
					}
				}

				if(item.use_yn == 'Y' || item.use_fg == 'Y'){
					item.is_set = true
				}
				return item
			})
		}
		, payment_mthd_list: function(){
			let t = []
			this.payment_list.filter( (item) => {

				if(item.is_check){
					item.use_yn = 'Y'
					item.use_fg = 'Y'
					t.push(item)
				}
			})
			return t
		}
		, is_save: function(){
			let t = true
			if(this.item.subscrp_plan_name && this.item.subscrp_plan_desctn && this.item.subscrp_amount && this.item.is_agree && this.payment_mthd_list.length > 0){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane_base
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.subscrp_plan_commis_rate = result.data.subscrp_plan_commis_rate
					this.items_payment = result.data.payment_mthd_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.item.upload_img_file_path = this.item.img_physl_path
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPlaneSetting: async function(pinnumber){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_subscribe_plan
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, pinnumber: pinnumber
						, subscrp_plan_number: this.item.subscrp_plan_number
						, img_file_path: this.item.img_file_path
						, subscrp_plan_name: this.item.subscrp_plan_name
						, subscrp_plan_desctn: this.item.subscrp_plan_desctn
						, subscrp_plan_price: Number(this.item.subscrp_amount)
						, payment_mthd_list: this.payment_mthd_list
						, subscrp_plan_state_code: this.item.subscrp_plan_state_code
					}
					, type: true
				})

				if(result.success){
					this.toPlane()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setFile: function(e){
			console.log(e)
			this.$set(this.item, 'img_file_path', e)
			this.$set(this.item, 'upload_img_file_path', e)
		}
		, setPayment: function(item){
			if(item.is_check){
				this.$set(item, 'is_check', false)
			}else{
				this.$set(item, 'is_check', true)
			}
		}
		, onPlaneSetting: function(){
			if(this.item.is_checked){
				this.postPlaneSetting()
			}else{
				this.$set(this.item, 'is_check', true)
			}
		}
		, onPin: function(){

			this.$bus.$emit('onPin', 'check')
			this.$bus.$off('pinCallback')
			this.$bus.$on('pinCallback', (type, pinnumber) => {
				console.log(type)
				this.postPlaneSetting(pinnumber)
			})
		}
		, goBack: function(){
			if(this.item.is_check){
				this.$set(this.item, 'is_check', false)
			}else{
				this.$bus.$emit('goBack')
			}
		}
		, setAgree: function(){

			this.$set(this.item, 'is_agree', !this.item.is_agree)
		}
		, setPlaneState: function(){
			if(this.item.subscrp_plan_state_code != 'SS00200002'){
				this.item.subscrp_plan_state_code = 'SS00200002'
			}else{
				this.item.subscrp_plan_state_code = 'SS00200003'
			}
		}
		, toPlane: function(){
			this.$bus.$emit('to', { name: 'SubscribePlane', params: { idx: this.$route.params.idx}})
		}
	}
	, async created() {
		await this.getCartel()
		await this.getPayment()
		await this.getPlan()
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>